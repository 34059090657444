@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: linear-gradient(#6760c6, #3075e6);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.scroll-down4 {
  width: 3px;
  height: 80px;
  background: #fff;
  animation: scrollDown4 2s ease infinite;
}

@keyframes scrollDown4 {
  0% {
    transform-origin: top;
    transform: scaleY(0);
  }
  45% {
    transform-origin: top;
    transform: scaleY(1);
  }
  55% {
    transform-origin: bottom;
    transform: scaleY(1);
  }
  100% {
    transform-origin: bottom;
    transform: scaleY(0);
  }
}
